import React from 'react'
import styled from 'styled-components'
import { CallIcon, Logo } from '../../../icons'

const SWrapper = styled.div`
    padding: 20px;
    background-color: ${({ theme: { colors } }) => colors.gray_1};

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        padding: 50px;
    }
`

const SLogo = styled(Logo)`
    height: 28px;

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        height: 22px;
        width: 160px;
    }
`

const SFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    border-top: 2px solid ${({ theme: { colors } }) => colors.gray_2};
    border-bottom: 2px solid ${({ theme: { colors } }) => colors.gray_2};

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 16px;
    }
`

const SPhoneInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-size: 14px;
`

const SPhone = styled.p`
    color: ${({ theme: { colors } }) => colors.secondary};
`

const SCallIcon = styled(CallIcon)`
    width: 24px;
    height: 24px;
`
const SLinks = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`

const SLink = styled.a`
    font-size: 14px;
    text-decoration: underline;
`

export const Footer = () => {
    return (
        <SWrapper>
            <SFooter>
                <SLogo />
                {/* FIXME: links */}
                <SLinks>
                    <SLink href="/privacy-policy.pdf" target="_blank">
                        Polityka Prywatności
                    </SLink>
                    <SLink href="/terms-and-conditions.pdf" target="_blank">
                        Regulamin
                    </SLink>
                </SLinks>
                <SPhoneInfo>
                    <p>Masz pytanie?</p>
                    <SCallIcon />
                    <SPhone>34 377 00 00</SPhone>
                </SPhoneInfo>
            </SFooter>
        </SWrapper>
    )
}
